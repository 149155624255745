import React, { createContext } from "react";
import { EnvironmentVariables, EnvironmentVariablesProps } from "./types";
import { EnvironmentVariablesMissingError } from "./errors";

export const EnvironmentVariablesContext =
  createContext<EnvironmentVariablesProps>({
    environmentVariables: {} as EnvironmentVariables,
  });

export type EnvironmentVariablesContextProps =
  React.PropsWithChildren<EnvironmentVariablesProps>;

/**
 * Context provider for providing environment variables to the React lifecycle.
 *
 * This should be used in favor of Next.js inline replacement of `NEXT_PUBLIC_*` variables.
 */
export function EnvironmentVariablesProvider({
  environmentVariables,
  children,
}: EnvironmentVariablesContextProps) {
  const parseResults = EnvironmentVariables.safeParse(environmentVariables);
  if (!parseResults.success) {
    throw new EnvironmentVariablesMissingError(parseResults.error);
  }

  return (
    <EnvironmentVariablesContext.Provider
      value={{
        environmentVariables,
      }}
    >
      {children}
    </EnvironmentVariablesContext.Provider>
  );
}
