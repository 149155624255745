import { EnvironmentVariables, EnvironmentVariablesProps } from "./types";
import { EnvironmentVariablesMissingError } from "./errors";

export const REQUIRED_CLIENT_VARIABLE_PREFIX = "NEXT_PUBLIC_";

/**
 * Reads environments variables intended for use in client side code and returns them as
 * props which will be then passed and provided by {@link EnvironmentVariablesProvider}.
 *
 * This must be exported as `getServerSideProps` for _all_ pages, otherwise an exception
 * will be thrown at build and runtime for the page in question.
 */
export const getServerSideEnvProps = async (): Promise<{
  props: EnvironmentVariablesProps;
}> => {
  const reassignments = {
    // Variable must be called SENTRY_RELEASE for sourcemap uploading
    ...(process.env.SENTRY_RELEASE && {
      NEXT_PUBLIC_SENTRY_RELEASE: process.env.SENTRY_RELEASE,
    }),
  };

  const sanitized = Object.fromEntries(
    Object.entries({ ...process.env, ...reassignments }).filter(([key]) =>
      key.startsWith(REQUIRED_CLIENT_VARIABLE_PREFIX),
    ),
  );

  const environmentVariables = EnvironmentVariables.safeParse(sanitized);

  if (!environmentVariables.success) {
    throw new EnvironmentVariablesMissingError(environmentVariables.error);
  }

  return {
    props: {
      environmentVariables: environmentVariables.data,
    },
  };
};
