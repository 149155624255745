/* eslint-disable no-empty */
import { isDevelopment } from "@constants";
import * as Sentry from "@sentry/nextjs";
import { CaptureContext } from "@sentry/types/types/scope";

interface SetSentryOrgOptions {
  id: string;
  kp_region?: string;
}

const SENTRY_UI_HOST = "https://abridge-ai.sentry.io";

export interface InitSentryOptions {
  dsn: string;
  environment: string;
  release?: string;
}

export const initSentry = (options: InitSentryOptions) => {
  const traceSampleRate = options.environment === "production" ? 0.15 : 0;

  Sentry.init({
    dsn: options.dsn,
    environment: options.environment,
    ignoreErrors: [
      // https://abridge-ai.sentry.io/issues/3067241190/?project=5969623
      "FirebaseError: Missing or insufficient permissions.",
      // https://abridge-ai.sentry.io/issues/5441839121/?project=5969623
      "InvalidStateError: Transition was aborted because of invalid state",
      // https://abridge-ai.sentry.io/issues/4243090137/?project=5969623
      "FirebaseError: Firebase: Error (auth/tenant-id-mismatch)",
    ],
    tracesSampler: (samplingContext) => {
      // Prevent traces for high volume endpoints
      if (
        samplingContext.name.includes("wavelength") ||
        samplingContext.name.includes("api/health") ||
        samplingContext.name.includes("api/recording/upload-state")
      ) {
        return 0;
      }

      return traceSampleRate;
    },
    beforeSend: (event) => {
      const headers = event?.request?.headers;
      if (headers && headers?.["User-Agent"]?.indexOf?.("detectify.com") > -1) {
        //returning null will drop the event, nothing will be sent to Sentry
        return null;
      }
      return event;
    },
    ...(options.release && { release: options.release }),
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
};

export const setSentryUser = (uid = ""): void => {
  try {
    if (isDevelopment || !uid) {
      console.info("Sentry User (Skipped) :: ", uid);
      return;
    }
    Sentry.setUser({
      id: uid,
    });
  } catch (err) {}
};

export const setSentryOrg = (options: SetSentryOrgOptions): void => {
  try {
    if (isDevelopment) {
      console.info("Sentry Org (Skipped) :: ", options);
      return;
    }
    Sentry.setTags({
      "org.id": options.id,
      "org.kp_region": options.kp_region,
    });
  } catch (err) {}
};

export const sentryError = (error: unknown, context?: CaptureContext): void => {
  try {
    if (isDevelopment) {
      console.error("Sentry Error (Skipped) :: ", error);
      return;
    }
    try {
      if (error) {
        const issueId = Sentry.captureException(error, context);
        console.info(
          `Issue logged to Sentry: ${SENTRY_UI_HOST}/issues/${issueId}`,
        );
      }
    } catch (e) {
      console.error("Error logging issue to Sentry:", e);
      console.error("Original Error:", error);
    }
  } catch (e) {}
};

export const sentryMessage = (message = "", context?: CaptureContext): void => {
  try {
    if (isDevelopment) {
      console.info("Sentry Message (Skipped) :: ", message);
      return;
    }
    if (message) {
      Sentry.captureMessage(message, context);
    }
  } catch (e) {}
};

export const sentryBreadcrumb = (
  message = "",
  context?: CaptureContext,
): void => {
  try {
    if (isDevelopment) {
      console.info("Sentry Breadcrumb (Skipped) :: ", message);
    }
    if (isDevelopment) return;
    if (message) {
      Sentry.addBreadcrumb({
        message,
        ...context,
      });
    }
  } catch (e) {}
};
