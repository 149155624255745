import { LogoutRequestBody } from "@api/logout";
import { logoutZendeskChat } from "@components/ZendeskChatWidget";
import { isDevelopment } from "@constants";
import { logAmplitude } from "@integrations/amplitude";
import { sentryError } from "@integrations/sentry";
import { logout as logoutRoute } from "@services/auth";
import { AbridgeIndexedDB, useDb } from "@utils/idxDb";
import { session } from "@utils/storage";
import { getAuth, User } from "firebase/auth";
import { MutableRefObject, useCallback, useRef } from "react";
import { isMobileOnly } from "react-device-detect";

export type LogOut = (
  from: string,
  options?: LogOutOptions,
  beforeLogout?: () => Promise<void>,
) => Promise<void>;

type TimeoutRef = MutableRefObject<NodeJS.Timeout | undefined>;
type CallbackRef = MutableRefObject<(() => Promise<void>) | undefined>;

interface UseSessionHookRefs {
  pageVisibilityCallbackRef: CallbackRef;
  sessionTimeoutRef: TimeoutRef;
}

export const SESSION_DURATION = isMobileOnly
  ? 1000 * 60 * 60 * 24 * 21 // mobile: 21days
  : 1000 * 60 * 60 * 12; // desktop/tablets: 12hrs

export interface UseSessionHook {
  logOut: LogOut;
  initializeSession: () => Promise<void>;
}

/**
 * Returns ms until session expiration should occur for user
 */
const getMsTilExpiration = async (user: User): Promise<number> => {
  const idTokenResult = await user?.getIdTokenResult();
  if (!idTokenResult || !idTokenResult?.claims?.auth_time) {
    // If we cannot parse token auth time from token then throw error and force logout
    throw new Error("Unable to get auth_time from user ID token");
  }
  const authTime = Number(idTokenResult?.claims?.auth_time) * 1000;
  return SESSION_DURATION - (Date.now() - authTime);
};

/**
 * Adds page visibility listener callback
 */
const addPageVisibilityListenerCallback = (
  refs: UseSessionHookRefs,
  db: AbridgeIndexedDB,
) => {
  // Ensure existing callback is removed
  removePageVisibilityListenerCallback(refs);
  const cb = async () => {
    if (document?.visibilityState === "visible" || document?.hidden === false) {
      await checkSessionExpiry(refs, db);
    }
  };
  document?.addEventListener("visibilitychange", cb);
  refs.pageVisibilityCallbackRef.current = cb;
};

/**
 * Removes page visibility listener callback
 */
const removePageVisibilityListenerCallback = (refs: UseSessionHookRefs) => {
  if (refs.pageVisibilityCallbackRef.current) {
    document?.removeEventListener(
      "visibilitychange",
      refs.pageVisibilityCallbackRef.current,
    );
  }
};

const clearSessionTimeout = (refs: UseSessionHookRefs) => {
  if (refs.sessionTimeoutRef.current) {
    clearTimeout(refs.sessionTimeoutRef.current);
  }
  refs.sessionTimeoutRef.current = undefined;
  removePageVisibilityListenerCallback(refs);
};

/**
 * Returns true if web recording is currently active
 * */
const isActivelyRecording = async (db: AbridgeIndexedDB) => {
  try {
    return Boolean(
      db?.isOpen?.() &&
        (await db.recordings
          ?.filter?.((r) => r?.recordingStatus === "recording")
          .count()),
    );
  } catch (error) {
    sentryError(error);
    return false;
  }
};

/**
 * Checks user ID token and calls logOut if user session is considered expired
 */
const checkSessionExpiry = async (
  refs: UseSessionHookRefs,
  db: AbridgeIndexedDB,
): Promise<void> => {
  try {
    const user = getAuth().currentUser;
    // Do nothing if no user is currently logged in
    if (!user) return;
    const millisecondsUntilExpiration = await getMsTilExpiration(user);
    if (isDevelopment) {
      console.log(
        `checkSessionExpiry :: ms until token expiration: ${millisecondsUntilExpiration}`,
      );
    }
    if (millisecondsUntilExpiration <= 0) {
      if (await isActivelyRecording(db)) {
        return; // do not logout if there is an active recording.
      }
      logOut("checkSessionExpiry :: timeout", refs);
    }
  } catch (err) {
    sentryError(err);
    logOut("checkSessionExpiry :: error", refs);
  }
};

/**
 * Options for {@link logOut}.
 */
export type LogOutOptions = LogoutRequestBody;

/**
 * Utility function to log out a user.
 */
const logOut = async (
  from: string,
  refs: UseSessionHookRefs,
  options?: LogOutOptions,
  beforeLogout?: () => Promise<void>,
) => {
  // Before logout callback
  await beforeLogout?.();

  // /logout route will unset cookies for next-firebase-auth
  const logoutResult = await logoutRoute(options ?? {});
  // sign out of firebase auth
  const auth = getAuth();
  console.log("Firebase auth :: signing out ::", from);
  auth?.signOut?.();
  logAmplitude("SOAP_DASHBOARD_USER_LOGOUT", { from });
  clearSessionTimeout(refs);
  logoutZendeskChat();
  // unset auth tenant ID
  console.log("Firebase auth :: Unsetting auth tenant");
  auth.tenantId = null;
  // unset local storage
  session.removeItem("LOGIN_EMAIL");
  if (logoutResult?.redirectUrl) {
    window.location.href = logoutResult.redirectUrl;
  }
};

const useSession = (): UseSessionHook => {
  const sessionTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const pageVisibilityCallbackRef = useRef<(() => Promise<void>) | undefined>(
    undefined,
  );
  const db = useDb();

  /**
   * Initializes session timeout timer. Should be invoked when new user logs in.
   */
  const _initializeSession = useCallback(async (): Promise<void> => {
    const refs = {
      sessionTimeoutRef,
      pageVisibilityCallbackRef,
    };
    try {
      clearSessionTimeout(refs);
      const user = getAuth()?.currentUser;
      // Do nothing is no user currently logged in
      if (!user) return;
      const millisecondsUntilExpiration = await getMsTilExpiration(user);

      if (millisecondsUntilExpiration <= 0) {
        logOut("initializeSession :: timeout", refs);
      } else {
        sessionTimeoutRef.current = setTimeout(async () => {
          if (await isActivelyRecording(db)) {
            return;
          }

          logOut("initializeSession :: timeout", refs);
        }, millisecondsUntilExpiration);
      }

      addPageVisibilityListenerCallback(refs, db);
      if (isDevelopment) {
        console.log(
          `initializeSession :: timer started. ms until session timeout: ${millisecondsUntilExpiration}`,
        );
      }
    } catch (err) {
      sentryError(err);
      logOut("initializeSession :: error", refs);
    }
  }, [db]);

  const _logOut = useCallback<LogOut>(
    async (from, options, beforeLogout) => {
      const refs = {
        sessionTimeoutRef,
        pageVisibilityCallbackRef,
      };
      await logOut(from, refs, options ?? {}, beforeLogout ?? undefined);
    },
    [
      // be cautious before adding deps which cause unnecessary re-renders
    ],
  );

  // // Debugging performance
  // if (isDevelopment) {
  //   console.info("useSession hook cycle");
  // }
  return {
    logOut: _logOut,
    initializeSession: _initializeSession,
  };
};

export default useSession;
