import { z } from "zod";
import { NotificationBannerSchema } from "@abridge/soap-common";

/**
 * Enum of known LaunchDarkly flag keys.
 */
export enum KnownFlagKey {
  ClientAPIKubernetesHosted = "client-api-enable-kubernetes-hosted",
  CodingHccIcd10 = "soap-dashboard-coding-hcc-icd10",
  CodingLevelOfService = "coding-level-of-service",
  NoteEditorGenerateTestAppointments = "note-editor-generate-test-appointments",
  NoteGenerationSandboxMode = "note-generation-sandbox-mode",
  NoteProxyAudioUrl = "note-editor-proxy-audio-url",
  NoteSettingsPhysicalExamTemplates = "note-settings-physical-exam-templates",
  NoteSettingsCustomPhysicalExamTemplates = "note-settings-custom-physical-exam-templates",
  NoteSettingsTimeSpentInEncounterSubsection = "note-settings-time-spent-in-encounter-subsection",
  QaViewerAdvancedSearch = "soap-dashboard-qa-viewer-advanced-search",
  QaViewerCdiUi = "soap-dashboard-qa-viewer-enable-cdi-ui",
  QaViewerOrders = "soap-dashboard-qa-viewer-enable-orders",
  QaViewerLinkedEvidence = "soap-dashboard-qa-viewer-linked-evidence",
  RemoteConfiguration = "remote-configuration",
  SendPvs = "send-pvs",
  UniversalNotificationBanner = "client-universal-notification-banner",
  VirtualVisits = "virtual-visits",
  WebRecording = "web-recording",
  WebRecordingFallbackKeyPairGeneration = "fallback-key-pair-generation",
  WebRecordingBrowserNotificationsOnCatastrophicErrors = "browser-notifications-on-catastrophic-web-recorder-errors",
  WebRecordingMinimumRecordingStorageMib = "web-recording-minimum-recording-storage-mib",
  WebRecordingOnboarding = "web-recording-onboarding",
  WebRecordingDebugger = "web-recording-debugger-ui",
  WebRecordingChunkedMediaRecorder = "web-recording-chunked-media-recorder",
  WebRecordingDeleteOnEncounterAlreadyProcessed = "web-recording-delete-on-encounter-already-processed",
}

export const KnownFlagValueRecord = z
  .object({
    [KnownFlagKey.ClientAPIKubernetesHosted]: z.boolean(),
    [KnownFlagKey.CodingHccIcd10]: z.boolean(),
    [KnownFlagKey.CodingLevelOfService]: z.boolean(),
    [KnownFlagKey.NoteEditorGenerateTestAppointments]: z.boolean(),
    [KnownFlagKey.NoteGenerationSandboxMode]: z.boolean(),
    [KnownFlagKey.NoteSettingsPhysicalExamTemplates]: z.boolean(),
    [KnownFlagKey.NoteSettingsTimeSpentInEncounterSubsection]: z.boolean(),
    [KnownFlagKey.NoteSettingsCustomPhysicalExamTemplates]: z.boolean(),
    [KnownFlagKey.NoteProxyAudioUrl]: z.boolean(),
    [KnownFlagKey.QaViewerAdvancedSearch]: z.boolean(),
    [KnownFlagKey.QaViewerCdiUi]: z.boolean(),
    [KnownFlagKey.QaViewerOrders]: z.boolean(),
    [KnownFlagKey.QaViewerLinkedEvidence]: z.boolean(),
    [KnownFlagKey.RemoteConfiguration]: z.boolean(),
    [KnownFlagKey.SendPvs]: z.boolean(),
    [KnownFlagKey.UniversalNotificationBanner]: NotificationBannerSchema,
    [KnownFlagKey.VirtualVisits]: z.boolean(),
    [KnownFlagKey.WebRecording]: z.boolean(),
    [KnownFlagKey.WebRecordingFallbackKeyPairGeneration]: z.boolean(),
    [KnownFlagKey.WebRecordingBrowserNotificationsOnCatastrophicErrors]:
      z.boolean(),
    [KnownFlagKey.WebRecordingMinimumRecordingStorageMib]: z
      .number()
      .int()
      .positive(),
    [KnownFlagKey.WebRecordingOnboarding]: z.boolean(),
    [KnownFlagKey.WebRecordingDebugger]: z.boolean(),
    [KnownFlagKey.WebRecordingChunkedMediaRecorder]: z.boolean(),
    [KnownFlagKey.WebRecordingDeleteOnEncounterAlreadyProcessed]: z.boolean(),
  })
  .partial();

export type KnownFlagValueRecord = z.infer<typeof KnownFlagValueRecord>;

/**
 * Returns the value type for a given flag key.
 */
export type KnownFlagValue<K> = K extends KnownFlagKey
  ? NonNullable<KnownFlagValueRecord[K]>
  : never;

/**
 * Returns the schema for parsing a known flag value.
 */
export const getKnownFlagSchema = <K extends KnownFlagKey>(key: K) =>
  KnownFlagValueRecord.shape[key];
