import { encounter } from "@abridge/encounter-schema";
import {
  Firestore_User_Account,
  Firestore_User_Account_Features,
  NoteSectionsAndSubsections,
  PractitionerContext,
} from "@abridge/soap-common";
import { LogoutRequestBody } from "@api/logout";
import { isDevelopment } from "@constants/index";
import {
  useWebRecordingSupport,
  WebRecordingSupport,
} from "@contexts/recording/support";
import useSession, { LogOut } from "@hooks/session";
import {
  logAmplitude,
  setAmplitudeUser,
  setAmplitudeUserProperties,
} from "@integrations/amplitude";
import { clearBrazeSession, setBrazeUser } from "@integrations/braze";
import { sentryError, setSentryOrg, setSentryUser } from "@integrations/sentry";
import { isAxiosError } from "@services/axios";
import {
  subscribeUserAccountListener,
  updateUserAccountAttributes,
  updateUserProfile as updateUserProfileService,
} from "@services/firebase";
import {
  getUserConfigurations,
  NoteSettings,
  updateUserNoteSettings,
  UserConfigurationsResponse,
} from "@services/functions";
import { UserPaymentProfile, UserProfile } from "@types_/soap";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { Unsubscribe, Timestamp } from "firebase/firestore";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { debounce, omit, size } from "lodash";
import { useRouter } from "next/router";
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { isDesktop } from "react-device-detect";
import {
  getEditorNoteSectionConfigWithDependantSubsectionChanges,
  getHideSectionsWithDependantSubsectionChanges,
} from "@abridge/web-ui";
import { DateTime } from "luxon";
import { useDb } from "@utils/idxDb";

export type SaveState = "saved" | "idle";

export type UpdateNoteSettingsOpts =
  | {
      section: NoteSectionsAndSubsections;
      hidden: boolean;
    }
  | {
      section: NoteSectionsAndSubsections;
      style: string;
      format?: string;
    }
  | {
      section: NoteSectionsAndSubsections;
      newStyle: string;
      newFormat?: string;
    }
  | {
      section: NoteSectionsAndSubsections;
      templateSettings: encounter.NoteSectionTemplateSettings;
    };

export type UserAuthContextType = {
  /**
   * When present we have successfully authenticated with `fireabse/auth` package
   */
  firebaseAuthUid?: string;
  /**
   * The soap-dashboard-accounts user document data
   */
  user?: Firestore_User_Account | undefined;
  /**
   * Inidicates if the soap-dashboard-accounts user document has been initialized
   */
  userDocumentInitialized?: boolean;
  /**
   * User feature flags
   */
  features: Firestore_User_Account_Features;
  /**
   * True if user is a scribe
   */
  isScribe: boolean;
  /**
   * Function to end the current user's session and properly log them out
   */
  logOut: LogOut;
  /**
   * User's profile and account details state; init from the user account doc
   */
  userProfile: UserProfile;
  /**
   * Function to update userProfile state (partial update)
   */
  updateUserProfile: (data: Partial<UserProfile>) => void;
  /**
   * Update user profile changes to db
   */
  saveUserProfile: () => void;
  /**
   * User's payment profile state; init from the user account doc
   */
  userPaymentProfile: UserPaymentProfile;
  /**
   * Sign out the user
   */
  signOutUser: (from: string, options?: LogoutRequestBody) => void;
  /**
   * User configuration; fetched at login/user init
   */
  userConfigurations?: UserConfigurationsResponse;
  userConfigurationsLoading: boolean;
  /**
   * Is the user integrated with ehr / toggle to show schedule vs non-integrated
   */
  isIntegratedUser: boolean | undefined;
  /**
   * GCP Tenant ID
   */
  firebaseAuthTenantId?: string;
  webRecordingSupport: WebRecordingSupport;
  noteSettings?: NoteSettings;
  updateNoteSettings: (
    options: UpdateNoteSettingsOpts,
    enableTimeSpentInEncounterSubsection?: boolean,
  ) => void;
  noteSettingsSaveState: SaveState;
  userProfileSaveState: SaveState;
  userProfileErrors?: Record<string, string>;
  setUserProfileErrors: (errors: Record<string, string>) => void;
};

const mappedPractitionerContextSpecialties = (
  userDoc?: Firestore_User_Account,
): (encounter.Specialties | encounter.CanonicalSpecialties)[] | null => {
  if (!userDoc?.practitionerContexts) {
    return null;
  }

  // There is no current requirement to separate out these specialties in this context.
  // For the sake of determining what may be relevant to the provider based on specialty,
  // we can combine them all.
  const allContexts: PractitionerContext[] = Object.values(
    userDoc.practitionerContexts,
  );

  return allContexts.reduce(
    (
      acc: (encounter.Specialties | encounter.CanonicalSpecialties)[],
      context,
    ) => {
      const mappedSpecialties = context.mappedSpecialties || [];
      return [...acc, ...mappedSpecialties];
    },
    [],
  );
};

export const defaultContextValue: UserAuthContextType = {
  firebaseAuthUid: undefined,
  user: undefined,
  userDocumentInitialized: undefined,
  features: {},
  isScribe: false,
  logOut: async () => console.warn("logOut has not yet been initialized"),
  userProfile: {},
  updateUserProfile: () =>
    console.warn("updateUserProfile has not yet been initialized"),
  saveUserProfile: async () =>
    console.warn("saveUserProfile has not yet been initialized"),
  userPaymentProfile: {
    isTrialing: false,
    hasPaymentMethod: false,
    hasBillingConnected: false,
  },
  signOutUser: () => console.warn("signOutUser has not yet been initialized"),
  userConfigurations: undefined,
  userConfigurationsLoading: true,
  isIntegratedUser: undefined,
  webRecordingSupport: WebRecordingSupport.defaultState(),
  noteSettings: undefined,
  updateNoteSettings: () =>
    console.warn("updateNoteSettings has not yet been initialized"),
  noteSettingsSaveState: "idle",
  userProfileSaveState: "idle",
  userProfileErrors: undefined,
  setUserProfileErrors: () =>
    console.warn("setUserProfileErrors has not yet been initialized"),
};

export const UserContext =
  createContext<UserAuthContextType>(defaultContextValue);

export const UserContextProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const previouslyAuthenticatedUser = useRef<string>();
  const client = useLDClient();
  const db = useDb();
  const [user, setUser] = useState<Firestore_User_Account>();
  const [firebaseAuthUid, setFirebaseAuthUid] = useState<string>();
  const [firebaseAuthTenantId, setFirebaseAuthTenantId] = useState<
    string | undefined
  >();
  const [userDocumentInitialized, setUserDocumentInitialized] =
    useState<boolean>();
  const [features, setFeatures] = useState<Firestore_User_Account_Features>({});
  const [isScribe, setIsScribe] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<UserProfile>({});
  const [userPaymentProfile, setUserPaymentProfile] =
    useState<UserPaymentProfile>({
      isTrialing: false,
      hasPaymentMethod: false,
      hasBillingConnected: false,
    });
  const [noteSettings, setNoteSettings] = useState<NoteSettings | undefined>(
    undefined,
  );
  const [userConfigurations, setUserConfigurations] = useState<
    UserConfigurationsResponse | undefined
  >(undefined);
  const [userConfigurationsLoading, setUserConfigurationsLoading] =
    useState(true);
  const [isIntegratedUser, setIsIntegratedUser] = useState<boolean | undefined>(
    undefined,
  );
  const unsubscribeUserAccountListener = useRef<Unsubscribe>();
  const unsubscribeAuthStateChangeListener = useRef<Unsubscribe>();
  const [noteSettingsSaveState, setNoteSettingsSaveState] =
    useState<SaveState>("idle");
  const [userProfileSaveState, setUserProfileSaveState] =
    useState<SaveState>("idle");
  const [userProfileErrors, setUserProfileErrors] =
    useState<Record<string, string>>();

  const { logOut, initializeSession } = useSession();

  const signOutUser = React.useCallback(
    (from: string, options?: LogoutRequestBody) => {
      // user is not logged in: unset user and logout explicitly.
      if (isDevelopment) {
        console.info("UserContext :: signOutUser called :: from: " + from);
      }
      if (unsubscribeUserAccountListener?.current) {
        unsubscribeUserAccountListener?.current?.();
      }
      setUser(undefined);
      setFirebaseAuthUid(undefined);
      setUserDocumentInitialized(undefined);
      setFeatures({});
      setIsScribe(false);
      clearBrazeSession();
      setUserProfile({});
      setUserPaymentProfile({
        isTrialing: false,
        hasPaymentMethod: false,
        hasBillingConnected: false,
      });
      logOut("UserContext :: signOutUser :: from: " + from, options);
      previouslyAuthenticatedUser.current = undefined;
    },
    [logOut],
  );

  const persistUserProfile = useCallback(async (data: Partial<UserProfile>) => {
    try {
      await updateUserProfileService(data);

      setUserProfileSaveState("saved");
      setUserProfileErrors((errors) => omit(errors, Object.keys(data)));
    } catch (error) {
      if (!isAxiosError(error)) {
        throw error;
      }

      const responseData = error.response?.data;

      if (responseData?.errors) {
        setUserProfileErrors((errors) => ({
          ...errors,
          ...responseData.errors,
        }));
      } else {
        sentryError(error);
      }
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPersistUserProfile = useCallback(
    debounce(persistUserProfile, 250, { leading: true }),
    [persistUserProfile],
  );

  const updateUserProfile = useCallback(
    async (data: Partial<UserProfile>) => {
      setUserProfile((up) => {
        return {
          ...(up || {}),
          ...(data || {}),
        };
      });

      if (data) {
        await debouncedPersistUserProfile(data);
      }
    },
    [debouncedPersistUserProfile],
  );

  const saveUserProfile = useCallback(async () => {
    try {
      await updateUserProfile(userProfile);
      updateUserAccountAttributes(user?.id || "", {
        onboarding_completed: true,
      });
    } catch (error: unknown) {
      sentryError(error);
    }
  }, [user?.id, userProfile, updateUserProfile]);

  const fetchAndSetUserConfigurations = useCallback(async () => {
    setUserConfigurationsLoading(true);
    try {
      const uc = await getUserConfigurations();
      setUserConfigurations(uc);
      setNoteSettings({
        hideSections: uc.editorConfig?.hideSections,
        noteStyles: uc.mlConfig?.noteStyles,
        editorNoteSectionConfig: uc.editorConfig?.noteSectionConfig,
        mlNoteSectionConfig: uc.mlConfig?.noteSectionConfig,
      });
    } catch (e) {
      sentryError(e);
      setUserConfigurations({});
      setNoteSettings(undefined);
    }
    setUserConfigurationsLoading(false);
  }, []);

  // Listen for changes to authenticated user
  useEffect(() => {
    const auth = getAuth();
    if (isDevelopment) {
      console.info("UserContext :: subscribing auth state listener");
    }
    unsubscribeAuthStateChangeListener.current = onAuthStateChanged(
      auth,
      async (f_user) => {
        if (isDevelopment) {
          console.info("UserContext :: onAuthStateChanged", f_user);
        }
        setFirebaseAuthUid(f_user?.uid || undefined);
        setFirebaseAuthTenantId(f_user?.tenantId ?? undefined);
        if (f_user?.uid) {
          // user is logged in
          // setup user account listener. If no account, setup a new account document.
          subscribeToUserAccountDoc(f_user);
          previouslyAuthenticatedUser.current = f_user?.uid;
          setAmplitudeUser(f_user?.uid);
          setBrazeUser(f_user?.uid);
          setSentryUser(f_user?.uid);
          // Set session timeout listeners.
          initializeSession();
        } else {
          if (previouslyAuthenticatedUser.current) {
            // If there was a previously authenticated user, sign them out
            // This prevents issues where we try to sign out a user that was never signed in to begin with
            // Previously we could fire signOutUser on app startup which makes no senses
            if (isDevelopment) {
              console.info(
                "UserContext :: onAuthStateChanged :: no user ID detected with previously authenticated user. signing out",
                {
                  f_user,
                  previouslyAuthenticatedUser:
                    previouslyAuthenticatedUser.current,
                },
              );
            }
            signOutUser(
              "onAuthStateChanged :: previouslyAuthenticatedUser = " +
                previouslyAuthenticatedUser.current,
            );
          } else {
            if (isDevelopment) {
              console.info(
                "UserContext :: onAuthStateChanged :: no user ID detected. no previously authenticated user",
                {
                  f_user,
                  previouslyAuthenticatedUser:
                    previouslyAuthenticatedUser.current,
                },
              );
            }
          }
        }
      },
    );
    const subscribeToUserAccountDoc = async (f_user: User) => {
      if (isDevelopment) {
        console.info(
          "UserContext :: subscribeToUserAccountDoc :: subscribing to user account document",
          f_user,
        );
      }
      // TODO is this bad??? shouldn't we always unsubscribe???
      if (!f_user?.uid) {
        if (isDevelopment) {
          console.warn(
            "UserContext :: subscribeToUserAccountDoc :: no user ID... aborting",
          );
        }
        return;
      }
      if (unsubscribeUserAccountListener?.current) {
        if (isDevelopment) {
          console.info(
            "UserContext :: subscribeToUserAccountDoc :: unsubscribing current document listener",
          );
        }
        unsubscribeUserAccountListener?.current?.();
      }
      if (isDevelopment) {
        console.info(
          "UserContext :: subscribeToUserAccountDoc :: subscribing new document listener for user",
          f_user?.uid,
        );
      }
      unsubscribeUserAccountListener.current =
        await subscribeUserAccountListener(
          f_user?.uid,
          async (snapshot) => {
            const userDocData = snapshot.data();
            const docExists: boolean =
              !!snapshot?.exists() && !!userDocData?.id;
            if (isDevelopment) {
              console.info(
                "UserContext :: userAccountDocListener :: snapshot updated",
                { docExists, userDocData },
              );
            }
            setUserDocumentInitialized(docExists);
            setUser(userDocData);
            setFeatures(userDocData?.features || {});
            if (userDocData?.identity_verified && !userConfigurations) {
              fetchAndSetUserConfigurations();
            }
            setUserProfile((up) => {
              const updated: UserProfile = { ...(up || {}) };
              // Replace any empty user profile values with existing user document data
              // displayName
              updated.displayName =
                updated?.displayName || userDocData?.displayName || "";
              // specialty
              updated.specialty =
                updated?.specialty || userDocData?.specialty || "";
              updated.practitionerContextSpecialties =
                mappedPractitionerContextSpecialties(updated) ||
                mappedPractitionerContextSpecialties(userDocData) ||
                [];
              // title
              updated.title = updated?.title || userDocData?.title || "";
              // institution (optional)
              updated.institution =
                updated?.institution || userDocData?.institution || "";
              updated.ehrUsed = updated?.ehrUsed || userDocData?.ehrUsed || "";
              // numberOfPatients (optional)
              updated.numberOfPatients =
                updated?.numberOfPatients ||
                userDocData?.numberOfPatients ||
                "";
              // hoursOfCharting (optional)
              updated.hoursOfCharting =
                updated?.hoursOfCharting || userDocData?.hoursOfCharting || "";
              // referredFrom (optional)
              updated.referredFrom =
                updated?.referredFrom || userDocData?.referredFrom || "";
              // kp_NUID (kp required| optional)
              updated.kp_NUID = updated?.kp_NUID || userDocData?.kp_NUID || "";
              // kp_region (kp required| optional)
              updated.kp_region =
                updated?.kp_region || userDocData?.kp_region || "";
              // kp_region (kp required| optional)
              updated.kp_servicearea =
                updated?.kp_servicearea || userDocData?.kp_servicearea || "";
              return updated;
            });
            setUserPaymentProfile((upp) => {
              const updated: UserPaymentProfile = { ...(upp || {}) };
              const onlyPositive = (n: number) => (n <= 0 ? 0 : n);
              const subscriptionStatus = user?.subscription?.status;
              updated.isTrialing = subscriptionStatus === "trialing";
              updated.hasPaymentMethod = !!user?.subscription?.paymentMethod;
              // hasBillingConnected is true if user has a subscription connection via Strip
              updated.hasBillingConnected = !!user?.subscription;
              updated.subscriptionDaysRemaining = onlyPositive(
                Math.ceil(
                  DateTime.fromJSDate(
                    (
                      user?.subscription?.currentPeriodEnd as Timestamp
                    )?.toDate?.(),
                  ).diffNow("days")?.days || 0.1,
                ) || 0,
              );
              return updated;
            });
            setIsScribe(userDocData?.org === "scribes");
            setAmplitudeUserProperties({
              ...(userDocData?.org && {
                org: userDocData.org,
              }),
              ...(userDocData?.role && {
                role: userDocData.role,
              }),
              ...(userDocData?.roles && {
                roles: userDocData.roles,
              }),
              ...(userDocData?.kp_region && {
                kp_region: userDocData.kp_region,
              }),
            });
            if (userDocData?.org) {
              setSentryOrg({
                id: userDocData.org,
                kp_region: userDocData.kp_region,
              });
            }
            if (userDocData?.id && userDocData?.org) {
              if (client) {
                try {
                  await client.identify({
                    kind: "multi",
                    user: {
                      key: userDocData.id,
                      ...(userDocData?.kp_region && {
                        kp_region: userDocData.kp_region,
                      }),
                      ...(userDocData?.kp_NUID && {
                        kp_nuid: userDocData.kp_NUID,
                      }),
                      ...(userDocData?.email && {
                        email: userDocData.email,
                      }),
                    },
                    org: { key: userDocData.org },
                    application: { key: "soap-dashboard" },
                  });
                } catch (err) {
                  logAmplitude("SOAP_DASHBOARD_LD_IDENTIFY_ERROR", {
                    error: err,
                    org: userDocData.org,
                    uid: userDocData.id,
                  });
                }
                console.info(
                  "UserContext :: userAccountDocListener :: Identified to LaunchDarkly",
                );
              }
            }

            // Redirect to pending access page if access is revoked
            if (user?.identity_verified && !userDocData?.identity_verified) {
              if (isDevelopment) {
                console.info(
                  "UserContext :: userAccountDocListener :: user identity_verified changed to false. signing out...",
                );
              }
              unsubscribeUserAccountListener?.current?.();
              router.push("/pending-invite");
            }
          },
          (err) => {
            signOutUser("UserAccountListener :: error");
            sentryError(err);
            console.error(
              "UserContext :: userAccountDocListener :: error occurred in user account doc listener",
              err,
            );
          },
        );
    };
    return () => {
      if (isDevelopment) {
        console.info("UserContext :: unmounting... unsubscribing listeners");
      }
      unsubscribeAuthStateChangeListener.current?.();
      unsubscribeUserAccountListener?.current?.();
    };
  }, [
    signOutUser,
    user?.identity_verified,
    initializeSession,
    fetchAndSetUserConfigurations,
    router,
    userConfigurations,
    client,
  ]);

  const webRecordingSupport = useWebRecordingSupport(
    {
      user,
      userConfigurations,
      isDesktop,
      db,
    },
    [
      user?.id,
      userConfigurations?.editorConfig?.enableWebRecording,
      user?.onboarding_completed,
      userConfigurationsLoading,
    ],
  );

  useEffect(() => {
    if (!user) return;
    setIsIntegratedUser(!!size(user?.ehrInstances));
  }, [user, user?.ehrInstances]);

  const determineUpdatedNoteSettings = (
    options: UpdateNoteSettingsOpts,
    enableTimeSpentInEncounterSubsection?: boolean,
  ): NoteSettings => {
    const localNoteSettings = { ...noteSettings };

    // If UpdateNoteSettingsOpts contain style or format options keep both noteStyles and mlNoteSectionConfig in sync
    // FIXME: Removed dual logic with the removal of the `note-settings-pbap-format-and-style` LD toggle.
    if ("style" in options || "newStyle" in options || "newFormat" in options) {
      if ("style" in options) {
        localNoteSettings["noteStyles"] = {
          ...localNoteSettings?.noteStyles,
          [options.section]: options.style,
        };
      }
      if ("newStyle" in options) {
        localNoteSettings["mlNoteSectionConfig"] = {
          ...localNoteSettings?.mlNoteSectionConfig,
          [options.section]: {
            ...(localNoteSettings?.mlNoteSectionConfig || {})[options.section],
            style: options.newStyle,
          },
        };
      }
      if ("newFormat" in options) {
        localNoteSettings["mlNoteSectionConfig"] = {
          ...localNoteSettings?.mlNoteSectionConfig,
          [options.section]: {
            ...(localNoteSettings?.mlNoteSectionConfig || {})[options.section],
            format: options.newFormat,
          },
        };
      }
      return localNoteSettings;
    }

    if ("templateSettings" in options) {
      localNoteSettings["mlNoteSectionConfig"] = {
        ...localNoteSettings?.mlNoteSectionConfig,
        [options.section]: {
          ...omit(
            (localNoteSettings?.mlNoteSectionConfig || {})[options.section],
            "style", // TODO: Remove this once we we remove the PE Template toggle
          ),
          templateSettings: options.templateSettings,
        },
      };
      return localNoteSettings;
    }

    // De-duplicate section if exists, then decide whether to add or not.
    const hideSections = [
      ...(noteSettings?.hideSections?.filter((s) => s !== options.section) ??
        []),
      ...(options.hidden ? [options.section] : []),
    ];
    const editorNoteSectionConfig = {
      ...noteSettings?.editorNoteSectionConfig,
      [options.section]: {
        ...(noteSettings?.editorNoteSectionConfig || {})[options.section],
        visible: !options.hidden,
      },
    };
    const hideSectionsWithDependantSubsectionChanges =
      getHideSectionsWithDependantSubsectionChanges(
        hideSections,
        options.section,
        options.hidden,
      );
    const editorNoteSectionConfigWithDependantSubsectionChanges =
      getEditorNoteSectionConfigWithDependantSubsectionChanges(
        editorNoteSectionConfig,
        options.section,
        options.hidden,
      );

    return {
      ...noteSettings,
      hideSections: enableTimeSpentInEncounterSubsection
        ? hideSectionsWithDependantSubsectionChanges
        : hideSections,
      editorNoteSectionConfig: enableTimeSpentInEncounterSubsection
        ? editorNoteSectionConfigWithDependantSubsectionChanges
        : editorNoteSectionConfig,
    };
  };

  const updateNoteSettings = async (
    options: UpdateNoteSettingsOpts,
    enableTimeSpentInEncounterSubsection?: boolean,
  ) => {
    setNoteSettingsSaveState("saved");
    const updatedSettings = determineUpdatedNoteSettings(
      options,
      enableTimeSpentInEncounterSubsection,
    );
    setNoteSettings(updatedSettings);
    await updateUserNoteSettings(updatedSettings);
  };

  useEffect(() => {
    if (noteSettingsSaveState === "saved") {
      const timeout = setTimeout(() => setNoteSettingsSaveState("idle"), 1500);

      () => clearTimeout(timeout);
    }
  }, [noteSettingsSaveState]);

  useEffect(() => {
    if (userProfileSaveState === "saved") {
      const timeout = setTimeout(() => setUserProfileSaveState("idle"), 1500);

      () => clearTimeout(timeout);
    }
  }, [userProfileSaveState]);

  return (
    <UserContext.Provider
      value={{
        firebaseAuthUid,
        firebaseAuthTenantId,
        user,
        userDocumentInitialized,
        features,
        isScribe,
        logOut,
        signOutUser,
        userProfile,
        userProfileSaveState,
        updateUserProfile,
        saveUserProfile,
        userPaymentProfile,
        userConfigurations,
        userConfigurationsLoading,
        isIntegratedUser,
        webRecordingSupport,
        noteSettings,
        updateNoteSettings,
        noteSettingsSaveState,
        userProfileErrors,
        setUserProfileErrors,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
