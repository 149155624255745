import { useEnvironmentVariables } from "@contexts/environment";
import { LDProvider, ProviderConfig } from "launchdarkly-react-client-sdk";
import React from "react";

const DEFAULT_PROPS: Omit<ProviderConfig, "clientSideID"> = {
  options: {
    bootstrap: "localStorage",
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
};

export const LaunchDarklyProvider: React.FC = ({ children }) => {
  const { NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID } =
    useEnvironmentVariables();

  return (
    <LDProvider
      {...DEFAULT_PROPS}
      clientSideID={NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID}
    >
      {children}
    </LDProvider>
  );
};
