import { ZodError } from "zod";

export class EnvironmentVariablesMissingError extends TypeError {
  constructor(zodError?: ZodError) {
    let message =
      "All pages must export `getServerSideEnvVariables` as `getServerSideProps` for providing client side environment variables.";

    if (zodError) {
      const messages = Object.entries(zodError.flatten().fieldErrors).map(
        ([key, value]) => `${key}: ${value}`,
      );

      message += "\n" + messages.map((text) => `    ${text}`).join("\n") + "\n";
    }

    super(message);

    this.name = "EnvironmentVariablesMissingError";
  }
}
