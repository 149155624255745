import { useContext } from "react";
import { EnvironmentVariablesContext } from "./context";
import { EnvironmentVariables } from "./types";
import { EnvironmentVariablesMissingError } from "./errors";

/**
 * Returns parsed environment variables intended for us in the React context.
 */
export const useEnvironmentVariables = (): EnvironmentVariables => {
  const { environmentVariables } = useContext(EnvironmentVariablesContext);

  if (!environmentVariables) {
    throw new EnvironmentVariablesMissingError();
  }

  return environmentVariables;
};
