import { GetCustomTokenResponseData } from "@api/user/get-custom-token";
import {
  browserLocalPersistence,
  ConfirmationResult,
  getAuth,
  RecaptchaParameters,
  RecaptchaVerifier,
  setPersistence,
  signInWithCustomToken,
  signInWithPhoneNumber,
  UserCredential,
} from "firebase/auth";
import {
  getFunctions,
  httpsCallable,
  HttpsCallableResult,
} from "firebase/functions";
import { postToRoute } from "./axios";
import { LogoutRequestBody, LogoutResponseBody } from "@api/logout";

/**
 * Doc: https://firebase.google.com/docs/auth/web/auth-state-persistence
 */
export const signInWithPHNumber = async (
  phoneNumber: string,
  appVerifier: RecaptchaVerifier,
): Promise<ConfirmationResult> => {
  const auth = getAuth();
  const AUTH_PERSISTENCE = browserLocalPersistence;
  await setPersistence(auth, AUTH_PERSISTENCE);
  return signInWithPhoneNumber(auth, phoneNumber, appVerifier);
};

export const twilioSendCallCode = async (
  phoneNumber: string,
): Promise<HttpsCallableResult<unknown>> => {
  const functions = getFunctions();
  const sendVerification = httpsCallable(functions, "twilioSendVerification");
  return sendVerification({ to: phoneNumber, channel: "call" });
};

export const twilioConfirmCallCode = async (
  phoneNumber: string,
  code: string,
): Promise<HttpsCallableResult<unknown>> => {
  const functions = getFunctions();
  const validate = httpsCallable(functions, "twilioAuthenticatePhone");
  return validate({ phoneNumber, code });
};

export const twilioCallCodeSignIn = async (
  firebaseToken: string,
): Promise<UserCredential | null> => {
  if (!firebaseToken) return null;
  const auth = getAuth();
  return signInWithCustomToken(auth, firebaseToken);
};

/**
 * Utility function to manually log out a user AND revoke the refresh tokens.
 */
export const logout = async (
  data?: LogoutRequestBody,
): Promise<LogoutResponseBody> => {
  // If the user is initiating the logout, we need to send the auth headers
  // in order to be able to retrieve the logout redirect URL on the backend.
  const useAuthHeaders = !!data?.isUserInitiated;
  const res = await postToRoute("/api/logout", data ?? {}, useAuthHeaders);
  return res.data;
};

export const getCustomTokenForMobileApp =
  async (): Promise<GetCustomTokenResponseData> => {
    const res = await postToRoute<GetCustomTokenResponseData>(
      "/api/user/get-custom-token",
      {},
    );
    return res?.data;
  };
