import { useEffect } from "react";
import { canUseDOM } from "@utils/common";
import { sentryError } from "@integrations/sentry";
import { useEnvironmentVariables } from "@contexts/environment";

type ZendeskAction = "show" | "hide" | "open" | "logout";
export interface IZendeskWindow extends Window {
  zE: (widget: string, action: ZendeskAction) => void;
}
const SCRIPT_ID = "ze-snippet";

export const getZendeskClient = (): IZendeskWindow["zE"] | null => {
  if (!canUseDOM()) {
    throw new Error(
      "Zendesk client access failed: DOM is not available. Ensure this code is running in a browser environment.",
    );
  }
  return (window as unknown as IZendeskWindow).zE || null;
};

const executeWithZendeskClient = (
  action: (zE: IZendeskWindow["zE"]) => void,
  maxRetries = 10, // Maximum number of retries
  retryInterval = 500, // Interval between retries in milliseconds
) => {
  let attempts = 0;
  const attemptAction = () => {
    const zE = getZendeskClient();
    if (zE) {
      action(zE);
    } else if (attempts < maxRetries) {
      attempts++;
      setTimeout(attemptAction, retryInterval);
    } else {
      sentryError(
        new Error("Failed to initialize Zendesk client after maximum retries."),
      );
    }
  };
  attemptAction();
};
export const openZendeskChat = (): void => {
  executeWithZendeskClient((zE) => {
    zE("webWidget", "show");
    zE("webWidget", "open");
  });
};

export const hideZendeskChat = (): void => {
  executeWithZendeskClient((zE) => {
    zE("webWidget", "hide");
  });
};

export const logoutZendeskChat = (): void => {
  executeWithZendeskClient((zE) => {
    zE("webWidget", "hide");
    zE("webWidget", "logout");
  });
};

const ZendeskLauncher = () => {
  const envVariables = useEnvironmentVariables();

  useEffect(() => {
    if (!envVariables.NEXT_PUBLIC_ZENDESK_CHAT_KEY) {
      sentryError("Zendesk Chat is unavailable. Please configure the API key.");
      return;
    }
    // Check if the script is already loaded
    const existingScript = document.getElementById(SCRIPT_ID);
    if (existingScript) {
      return;
    }

    const script = document.createElement("script");
    script.id = SCRIPT_ID;
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${envVariables.NEXT_PUBLIC_ZENDESK_CHAT_KEY}`;
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      hideZendeskChat();
    };

    return () => {
      // Clean up the script once the component unmounts
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  // This component does not render any UI elements. It is responsible for
  // loading the Zendesk chat widget script and managing its lifecycle.
  return null;
};

export default ZendeskLauncher;
