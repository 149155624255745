import {
  EHRClientConfig,
  EditorConfig,
  FeedbackFormAnswers,
  Firestore_Soap_Conversation,
  Firestore_Soap_Encounter,
  HideSections,
  MLConfig,
  MLOutput_MedicalTerms,
  MLOutput_SoapNote,
  MLOutput_SoapTranscript,
  NoteSectionConfig,
  NoteStyles,
  PronounType,
  Rendered_SoapNote,
} from "@abridge/soap-common";
import { sentryError } from "@integrations/sentry";
import { IHCCSuspectedCode } from "@types_/soap";
import axios, { AxiosRequestConfig } from "axios";
import { getAuth } from "firebase/auth";
import {
  getFunctions as _getFunctions,
  FunctionsError,
  httpsCallable,
} from "firebase/functions";
import { debounce } from "lodash";
import size from "lodash/size";

export interface GetAudioUrlRequest {
  eid: string;
}
export interface GetAudioUrlResponse {
  audioUrl: string;
}

const getAuthHeaders = async (): Promise<AxiosRequestConfig["headers"]> => {
  try {
    const token = await getAuth()?.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Failed to get auth token from firebase user");
    }
    return { authorization: `Bearer ${token}` };
  } catch (e) {
    sentryError(e);
    throw e;
  }
};

const PROXY_PATH = "/cloudfunctions";

const axiosFunctions = axios.create({
  baseURL: PROXY_PATH,
});

axiosFunctions.interceptors.request.use(async (config) => {
  const authHeaders = await getAuthHeaders();

  config.headers = {
    ...config.headers,
    ...authHeaders,
  };

  return config;
});

/**
 * Custom method to get firebase functions instance
 * Enables usage of custom domain to proxy requests through app domain
 */
const getFunctions = () => {
  const fn = _getFunctions();
  // Set firebase functions custom domain
  // Doing this in getFunctions() itself does NOT work since the trailing path (/cloudfunctions) will be truncated
  // Setting this value directly like below works properly
  fn.customDomain = `${window.location.origin}${PROXY_PATH}`;
  return fn;
};

export const getAudioUrl = async (
  encounterId: string,
  isAudioProxyUrlEnabled: boolean,
  enableClientAPIKubernetesHosted: boolean,
): Promise<string> => {
  if (isAudioProxyUrlEnabled) {
    // Proxy audio url through our backend, add timestamp to prevent caching
    return `/api/recording/get-audio-stream?encounterId=${encounterId}&timestamp=${Date.now()}&enableClientAPIKubernetesHosted=${enableClientAPIKubernetesHosted}`;
  }

  const fn = httpsCallable<GetAudioUrlRequest, GetAudioUrlResponse>(
    getFunctions(),
    "audio-clinician-getSignedUrl",
  );
  const response = await fn({ eid: encounterId });
  return response.data.audioUrl;
};

export interface GetIdpOrgRequest {
  email: string;
}
export interface GetIdpOrgResponse {
  org?: string;
  mobileSSOUrl?: string;
}

export const getIdpOrg = async (email: string): Promise<string | undefined> => {
  const fn = httpsCallable<GetIdpOrgRequest, GetIdpOrgResponse>(
    getFunctions(),
    "auth-getIdp",
  );
  const response = await fn({ email });
  return response.data.org;
};

export interface GetEncounterDataRequest {
  eid: string;
  excludeEmptySections: boolean;
  insertPlaceholderSummaries: boolean;
}

export interface HttpsOptions {
  signal?: AbortSignal;
}

export interface GetEncounterDataResponse {
  transcript?: MLOutput_SoapTranscript;
  parsedNote?: Rendered_SoapNote;
  soapNote?: MLOutput_SoapNote;
  soapNoteEdited?: Rendered_SoapNote;
  priorNoteSummary?: string;
  afterVisitSummary?: string;
  afterVisitSummaryEdited?: string;
  medicalTerms?: MLOutput_MedicalTerms;
  feedback?: FeedbackFormAnswers;
  pvsFeedback?: FeedbackFormAnswers;
  icd10Codes?: Firestore_Soap_Conversation["icd10Codes"];
  icd10UserFeedback?: Firestore_Soap_Encounter["icd10UserFeedback"];
  icd10UserAddedCodes?: Firestore_Soap_Encounter["icd10UserAddedCodes"];
  pronouns?: PronounType;
  verbatimTranscript?: string;
}

/**
 * Fetches transcript uploaded to cloud storage vs in conversation doc
 */
export const getEncounterData = async (
  {
    eid,
    excludeEmptySections,
    insertPlaceholderSummaries,
  }: GetEncounterDataRequest,
  httpsOptions?: HttpsOptions,
): Promise<GetEncounterDataResponse> => {
  const response = await axiosFunctions.post(
    "getEncounterData",
    {
      data: {
        eid,
        excludeEmptySections,
        insertPlaceholderSummaries,
      },
    },
    {
      signal: httpsOptions?.signal,
    },
  );

  if (!response.data?.result) {
    throw new Error(`getEncounterData did not return result: ${eid}`);
  }

  return response.data.result;
};

export interface NewSoapUserRequest {
  source: string;
}
export interface NewSoapUserResponse {
  success: boolean;
  error?: string;
}

/**
 * Creates a new SOAP user
 */
export const newSoapUser = async ({
  loginEmail,
}: {
  loginEmail?: string;
}): Promise<NewSoapUserResponse> => {
  const fn = httpsCallable<NewSoapUserRequest, NewSoapUserResponse>(
    getFunctions(),
    "newSoapUser",
  );
  const { data } = await fn({
    source: "SOAP_DASHBOARD",
    ...(loginEmail && { email: loginEmail }),
  });
  return data;
};

export interface EelsInputTranscript {
  [sentenceKey: number]: { sent: { word: string }[] };
}

export interface GetLinkagesRequest {
  eid: string;
  transcript?: EelsInputTranscript;
  summary?: string;
}

export type PhraseIdCluster = number[];
export interface GetLinkagesResponse {
  utterance_ids: PhraseIdCluster[];
}
/**
 * Fetches linkages
 **/
export const getLinkages = async (
  eid: string,
  transcript: EelsInputTranscript,
  summary: string,
): Promise<GetLinkagesResponse> => {
  const fn = httpsCallable<GetLinkagesRequest, GetLinkagesResponse>(
    getFunctions(),
    "getLinkages",
  );
  const response = await fn({ eid, transcript, summary });
  return response.data;
};

export interface UserEditorConfig extends EditorConfig {
  displayPvsCopyPasteOnlyBadge?: boolean;
  displayPvsPrintButton?: boolean;
  web_recording?: boolean;
}

export interface UserConfigurationsResponse {
  mlConfig?: MLConfig;
  editorConfig?: UserEditorConfig;
  ehrClientConfig?: EHRClientConfig;
}
/**
 * Fetches user configurations
 **/
export const getUserConfigurations =
  async (): Promise<UserConfigurationsResponse> => {
    const fn = httpsCallable<{ method: "get" }, UserConfigurationsResponse>(
      getFunctions(),
      "userConfigurations",
    );
    const response = await fn({ method: "get" });
    return response.data;
  };

export interface NoteSettings {
  hideSections?: HideSections;
  noteStyles?: NoteStyles;
  editorNoteSectionConfig?: NoteSectionConfig;
  mlNoteSectionConfig?: NoteSectionConfig;
}

export const fetchAppointmentsByDate = async ({
  date,
  ehrScheduleResourceIds,
}: {
  date: {
    year: number;
    month: number;
    day: number;
  };
  ehrScheduleResourceIds?: {
    [ehrInstance: string]: string[];
  };
}): Promise<any> => {
  // todo: remove any
  const fn = httpsCallable<any>(
    getFunctions(),
    "scheduling-fetchAppointmentsByDate",
  );
  if (!date) return;
  const response = await fn({
    ...date,
    ...(size(ehrScheduleResourceIds) && { ehrScheduleResourceIds }),
  });
  return response?.data;
};

/**
 * Generate test appointments
 **/
export const generateTestAppointments = async (scheduleInfo: {
  numberOfAppointments?: number;
  scheduleResourceId?: string;
  ehrInstanceId?: string;
}): Promise<any> => {
  const fn = httpsCallable<any>(getFunctions(), "hl7-generateTestAppointments");
  const response = await fn({
    ehrInstanceId: scheduleInfo?.ehrInstanceId,
    scheduleResourceId: scheduleInfo?.scheduleResourceId,
    numberOfAppointments: scheduleInfo?.numberOfAppointments || 10,
  });
  return response.data;
};

/**
 * Updates user note settings.
 *
 * Debounced so that it can only be called once every second.
 **/
export const updateUserNoteSettings = debounce(
  async ({
    hideSections,
    noteStyles,
    editorNoteSectionConfig,
    mlNoteSectionConfig,
  }: NoteSettings): Promise<UserConfigurationsResponse> => {
    const fn = httpsCallable<
      NoteSettings & { method: "update" },
      UserConfigurationsResponse
    >(getFunctions(), "userConfigurations");
    const response = await fn({
      method: "update",
      hideSections,
      noteStyles,
      editorNoteSectionConfig,
      mlNoteSectionConfig,
    });
    return response.data;
  },
  1000,
  {
    leading: true,
  },
);

interface FetchSuspectedCodesOpts {
  encounterId: string;
  confirmedCodes: string[];
}

interface FetchSuspectedCodesResponse {
  suspected?: IHCCSuspectedCode[];
}

/**
 * Fetches suspected codes
 **/
export const fetchSuspectedCodes = async ({
  encounterId,
  confirmedCodes,
}: FetchSuspectedCodesOpts): Promise<FetchSuspectedCodesResponse> => {
  const fn = httpsCallable<
    FetchSuspectedCodesOpts,
    FetchSuspectedCodesResponse
  >(getFunctions(), "hcc-evaluate");
  const response = await fn({ encounterId, confirmedCodes });
  return response.data;
};

export interface UserAuthDataResponse {
  claims: Record<string, any>;
  parsedClaims: {
    email?: string;
    displayName?: string;
  };
  token: {
    name: string;
    iss: string;
    aud: string;
    auth_time: number;
    user_id: string;
    sub: string;
    iat: number;
    exp: number;
    email: string;
    email_verified: boolean;
    uid: string;
    firebase: {
      identities: Record<string, string[]>;
      sign_in_provider: string;
      sign_in_attributes: Record<string, string | string[]>;
      tenant: string;
    };
  };
}

/**
 * Fetch user auth data to troubleshoot SSO
 **/
export const getUserAuthData = async (): Promise<UserAuthDataResponse> => {
  const fn = httpsCallable<any, UserAuthDataResponse>(
    getFunctions(),
    "auth-userData",
  );
  const response = await fn({});
  return response.data;
};

/**
 * Updates verbatim transcript for the given encounter
 */
export const updateVerbatim = async (
  encounterId: string,
  verbatimTranscript: string,
): Promise<void> => {
  const fn = httpsCallable<any, void>(
    getFunctions(),
    "verbatim-updateVerbatim",
  );
  await fn({ encounterId, verbatimTranscript });
};

export const regenerateLevelOfServiceDocument = async (
  encounterId: string,
): Promise<unknown> => {
  const fn = httpsCallable<{ encounterId: string }, any>(
    getFunctions(),
    "regenerateLevelOfServiceDocument",
  );

  const response = await fn({ encounterId });

  if ((response as any).data?.status !== "ok") {
    throw new Error(
      `regenerateLevelOfServiceDocument did not return result: ${encounterId}`,
    );
  }

  return response.data.result;
};

export const regenerateMedicalCodingDocument = async (
  encounterId: string,
): Promise<unknown> => {
  const fn = httpsCallable<{ encounterId: string }, any>(
    getFunctions(),
    "regenerateMedicalCodingDocument",
  );

  const response = await fn({ encounterId });

  if ((response as any).data?.status !== "ok") {
    throw new Error(
      `regenerateMedicalCodingDocument did not return result: ${encounterId}`,
    );
  }

  return response.data.result;
};

export const generateSandboxSoapNote = async ({
  encounterId,
  mlConfig,
}: {
  encounterId: string;
  mlConfig?: MLConfig;
}): Promise<void> => {
  const fn = httpsCallable<any, void>(
    getFunctions(),
    "generateSandboxSoapNote",
  );
  const response = await fn({ encounterId, mlConfig });
  return response.data;
};

type AbridgeFunctionError = FunctionsError & {
  details?: {
    abridgeErrorCode?: string;
  };
};

/**
 * Type guard for errors emitted by cloud functions
 */
export const isFunctionsError = (
  error: unknown,
): error is AbridgeFunctionError => {
  return (
    !!error &&
    typeof error === "object" &&
    "code" in error &&
    typeof error.code === "string" &&
    error.code.startsWith("functions/")
  );
};
