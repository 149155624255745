import { z } from "zod";

export const DeployEnv = z.union([
  z.literal("production"),
  z.literal("staging"),
  z.literal("develop"),
  z.literal("local"),
]);

export type DeployEnv = z.infer<typeof DeployEnv>;

/**
 * Schema for all environment variables which can be accessed in the React lifecycle.
 *
 * All variables must be prefixed with `NEXT_PUBLIC_`.
 */
export const EnvironmentVariables = z.object({
  NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY: z.string().min(1),
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: z.string().min(1),
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN_PROXY_DESTINATION: z.string().min(1),
  NEXT_PUBLIC_FIREBASE_DATABASE_URL: z.string().min(1),
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: z.string().min(1),
  NEXT_PUBLIC_FIREBASE_APP_ID: z.string().min(1).optional(),
  NEXT_PUBLIC_COOKIE_SECURE: z.string().min(1),
  NEXT_PUBLIC_AMPLITUDE_CLIENT_KEY: z.string().optional(),
  NEXT_PUBLIC_AMPLITUDE_API_ENDPOINT: z.string().optional(),
  NEXT_PUBLIC_BRAZE_CLIENT_KEY: z.string().optional(),
  NEXT_PUBLIC_SENTRY_DSN: z.string().min(1),
  NEXT_PUBLIC_DEPLOY_ENV: DeployEnv,
  NEXT_PUBLIC_SHOW_BANNER: z.string().optional(),
  NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID: z.string().min(1),
  NEXT_PUBLIC_ZENDESK_CHAT_KEY: z.string().min(1).optional(),
  NEXT_PUBLIC_MOBILE_APP_SCHEME: z.string().min(1), // URL used during mobile app authentication
  NEXT_PUBLIC_SENTRY_RELEASE: z.string().min(1).optional(),
  NEXT_PUBLIC_TEST_NUMBERS: z.string().min(1).optional(),
});

export type EnvironmentVariables = z.infer<typeof EnvironmentVariables>;

export interface EnvironmentVariablesProps {
  environmentVariables: EnvironmentVariables;
}
