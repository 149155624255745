import Dexie, { Collection, DexieOptions, Table } from "dexie";
import {
  Identifier,
  RecordingChunk,
  Recording,
  RecordingUploadStatus,
} from "./types";
import pRetry, { Options as RetryOptions } from "p-retry";
import { v4 as uuidv4 } from "uuid";
import { filterNullDocuments } from "./utils";
import { once } from "lodash";
import { DeployEnv } from "@contexts/environment";

const RETRY_OPTIONS: RetryOptions = {
  retries: 3,
  minTimeout: 1000,
  factor: 2,
};

const COMPLETED_CHUNK_STATES: Set<RecordingUploadStatus> = new Set([
  RecordingUploadStatus.DELETED,
  RecordingUploadStatus.UPLOADED,
]);

export const getDatabaseName = (deployEnv?: DeployEnv) =>
  `abr_db${deployEnv && deployEnv !== "production" ? `_${deployEnv}` : ""}`;

export class AbridgeIndexedDB extends Dexie {
  recordings!: Table<Recording>;
  recordingChunks!: Table<RecordingChunk>;
  identifiers!: Table<Identifier, Identifier["key"]>;

  constructor(databaseName: string, options?: DexieOptions) {
    super(databaseName, { autoOpen: false, ...options });
    this.version(2).stores({
      recordings: "encounterId", // Primary key and indexed props
      recordingChunks: "chunkId, encounterId", // Primary key and indexed props
      identifiers: "key",
    });
  }

  public openDb = async (): Promise<void> => {
    if (!this.isOpen?.()) {
      await this.open?.();
    }
  };

  public addRecording(recording: Recording): Promise<void> {
    const add = () =>
      this.recordings.add(
        {
          uploaderVersion: "1",
          ...recording,
        },
        recording.encounterId,
      );
    return pRetry(add, RETRY_OPTIONS);
  }

  public updateRecording(
    id: string,
    recording: Partial<Recording>,
  ): Promise<number> {
    const update = () => this.recordings.update(id, recording);

    return pRetry(update, RETRY_OPTIONS);
  }

  public addRecordingChunk(chunk: RecordingChunk): Promise<void> {
    const add = () =>
      this.recordingChunks.add(
        { uploaderVersion: "1", ...chunk },
        chunk.chunkId,
      );

    return pRetry(add, RETRY_OPTIONS);
  }

  public updateRecordingChunk(
    id: string,
    chunk: Partial<RecordingChunk>,
  ): Promise<number> {
    const update = () => this.recordingChunks.update(id, chunk);

    return pRetry(update, RETRY_OPTIONS);
  }

  public getRetryableRecordingChunks(): Collection<RecordingChunk> {
    return this.recordingChunks
      .filter(filterNullDocuments(this))
      .filter(
        (chunk) =>
          !COMPLETED_CHUNK_STATES.has(chunk.uploadStatus) &&
          (Boolean(chunk.chunkData) || Boolean(chunk.chunkDataBuffer)),
      );
  }

  public getRetryableRecordingChunksByEncounter(
    encounterId: string,
  ): Promise<RecordingChunk[]> {
    return this.getRetryableRecordingChunks()
      .filter(filterNullDocuments(this))
      .filter((recChunk) => recChunk?.encounterId === encounterId)
      ?.toArray();
  }

  public updateRecordingChunksCollection(
    chunks: Collection<RecordingChunk>,
    updates: Partial<RecordingChunk>,
  ): Promise<number> {
    const update = () => chunks.modify(updates);

    return pRetry(update, RETRY_OPTIONS);
  }

  async countUploadedChunks(encounterId: string): Promise<number> {
    const count = await this.recordingChunks
      .filter(filterNullDocuments(this))
      .filter((recordingChunk) => recordingChunk.encounterId === encounterId)
      .filter(
        (recordingChunk) =>
          recordingChunk.uploadStatus === RecordingUploadStatus.UPLOADED,
      )
      .count();

    return count;
  }

  public async getBrowserId(): Promise<string> {
    return this.transaction("readwrite", this.identifiers, async () => {
      const browserId = await this.identifiers.get("browser");

      if (browserId) {
        return browserId.value;
      }

      const newBrowserIdValue = uuidv4();

      await this.identifiers.add({ key: "browser", value: newBrowserIdValue });

      return newBrowserIdValue;
    });
  }
}

/**
 * Creates instance of {@link AbridgeIndexedDB} or returns if already created.
 *
 * Please use {@link useDb} hook in production code.
 */
export const getDbInstance = once(
  (databaseName: string) => new AbridgeIndexedDB(databaseName),
);
