import { init, InitConfig } from "@abridge/next-firebase-auth";
import { getApps } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import { isClientSide } from "./common";
import { once } from "lodash";

const TWELVE_DAYS_IN_MS = 12 * 60 * 60 * 24 * 1000;

const getConfig = (
  firebaseClientInitConfig: InitConfig["firebaseClientInitConfig"],
) => {
  const { env } = process;

  return {
    debug: process?.env?.NODE_ENV !== "production",
    authPageURL: "/login",
    appPageURL: "/home",
    loginAPIEndpoint: "/api/login",
    logoutAPIEndpoint: "/api/logout",
    firebaseClientInitConfig,
    useFirebaseAdminDefaultCredential: true,
    cookies: {
      name: "abridge__notes--",
      keys: [env.COOKIE_SECRET_CURRENT, env.COOKIE_SECRET_PREVIOUS],
      httpOnly: true,
      maxAge: TWELVE_DAYS_IN_MS,
      overwrite: true,
      path: "/",
      sameSite: "strict",
      secure: env.NEXT_PUBLIC_COOKIE_SECURE === "true",
      signed: true,
    },
  };
};

/**
 * Initializes Firebase auth SDK for server side usage. Should exclusively be invoked
 * in the global scope in all `pages/api/*` routes and in `pages/_app.tsx`.
 */
const initAuth = (): void => {
  if (isClientSide()) {
    return;
  }

  const { env } = process;

  // Next.js attempts to execute all top-level code due to static page generation. However,
  // this code has no reason to be executed at build time, and forces us to define the Firebase
  // environment variables at build time which is incompatible with our deployment strategy.
  if (env.BUILD_PHASE) {
    return;
  }

  const firebaseClientInitConfig = {
    apiKey: env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY || "",
    authDomain: env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    appId: env.NEXT_PUBLIC_FIREBASE_APP_ID || "",
  };

  init(getConfig(firebaseClientInitConfig));
};

/**
 * Initializes Firebase auth SDK for client side usage. Should exclusively be
 * invoked first thing in the `pages/_app.tsx` component.
 */
export const initClientAuth = once(
  (firebaseClientInitConfig: InitConfig["firebaseClientInitConfig"]) => {
    init(getConfig(firebaseClientInitConfig));

    // Enable long polling to resolve network issues at Kaiser
    // https://github.com/firebase/firebase-js-sdk/issues/1674
    // https://groups.google.com/g/firebase-talk/c/7CNG0JY7P1E?pli=1
    // https://www.youtube.com/watch?v=GUdSbhZmDtg&t=116s
    if (isClientSide()) {
      const apps = getApps();
      const app = apps[0];
      if (app) {
        initializeFirestore(app, {
          experimentalAutoDetectLongPolling: true,
        });
        console.log(
          "Set experimentalAutoDetectLongPolling = true when initializing firestore",
        );
      }
    }
  },
);

export default initAuth;
