/* globals window */

import { Firestore_User_Account, normalizeWord } from "@abridge/soap-common";
import {
  defaultErrorMessage,
  phoneNumberErrorMessages,
  phoneVerificationErrorMessages,
} from "@constants";
import { sentryError } from "@integrations/sentry";
import { SimpleObject } from "@types_";
import { IncomingMessage } from "http";
import { last, size } from "lodash";
import toInteger from "lodash/toInteger";
import React from "react";
import {
  formatPhoneNumberIntl,
  formatPhoneNumber,
} from "react-phone-number-input";
import { sanitize } from "dompurify";
import IOSDownloadQRCode from "@public/graphics/qr-ios-download.svg";
import {
  getUA,
  browserVersion,
  browserName,
  osName,
  osVersion,
} from "react-device-detect";
import { session } from "./storage";
import { DateTime } from "luxon";
import { DeployEnv } from "@contexts/environment";

export const getAbsoluteURL = (url: string, req?: IncomingMessage): string => {
  let host;
  if (req) {
    host = req?.headers?.host;
  } else {
    if (typeof window === "undefined") {
      throw new Error(
        'The "req" parameter must be provided if on the server side.',
      );
    }
    host = window?.location?.host;
  }
  const isLocalhost = host?.indexOf("localhost") === 0;
  const protocol = isLocalhost ? "http" : "https";
  return `${protocol}://${host}${url}`;
};

export const formatPhoneNumberForDisplay = (
  phoneNumberString: string,
): string => {
  // Display US numbers in national format.
  if ((phoneNumberString || "").includes("+1")) {
    return formatPhoneNumber(phoneNumberString);
  }
  return formatPhoneNumberIntl(phoneNumberString) || phoneNumberString;
};

export const getVerificationErrorMessage = (error: {
  code: string;
}): string => {
  return (
    phoneVerificationErrorMessages[error && error.code] || defaultErrorMessage
  );
};

export const getPhNumberErrorMessage = (error?: {
  code: number | string;
}): string => {
  return (
    phoneNumberErrorMessages[(error && error?.code) || ""] ||
    defaultErrorMessage
  );
};

/**
 * Because server side rendering occurs we may not always have the `window` global when code executes
 * This method will check if the window global is safe to use
 * https://stackoverflow.com/a/39939932
 */
export const windowExists = (): boolean => !(typeof window === "undefined");

/**
 * Checks if the `window` global exists (aka code is running client-side) and then returns either the `window` global or an empty object.
 * Intended to prevent issues where we use `window` global throughout the codebase
 * @returns The `window` global (client side) or an empty object (server side)
 */
export const getWindow = (): Window | SimpleObject => {
  // https://stackoverflow.com/a/39939932

  if (windowExists()) {
    return window;
  } else {
    return {};
  }
};

/**
 * Returns number from the end of a string. If unable to parse will return `-1`
 * ex: `'my-string-with-number-1234'` will return `1234`
 * @param str String to return number from
 * @returns Number found at end of string or -1 if unable to parse number
 */
export const getNumberAtEndOfString = (str: string): number => {
  if (!str) {
    return -1;
  }
  const regex = /\d+$/; // any number of digits at end of string
  const numberStr = str.match(regex);
  if (!numberStr) {
    return -1;
  }
  return toInteger(numberStr);
};

export const parseDroppableIds = (
  str: string,
): {
  sectionId: number;
  topicsSupport: boolean;
} => {
  if (!str) {
    return {
      sectionId: -1,
      topicsSupport: false,
    };
  }
  const splitString = str?.split("-");
  const lastItem = last(splitString);

  if (lastItem === "topics") {
    return {
      sectionId: toInteger(splitString[size(splitString) - 2]),
      topicsSupport: true,
    };
  }

  return {
    sectionId: toInteger(splitString[size(splitString) - 1]),
    topicsSupport: false,
  };
};

/**
 * Returns formatted timestamp of audio playback
 * @param time timestamp of audio playback
 * @returns formatted string (HH:MM:SS or MM:SS)
 */
export const formatAudioTime = (time: number): string => {
  if (time < 3600) {
    return new Date(time * 1000).toISOString().substring(14, 19);
  }
  return new Date(time * 1000).toISOString().substring(11, 19);
};

/**
 *
 * @param el HTML DOM element
 * @returns
 */
export const isElementVisible = (el: Element): boolean => {
  const rect = el?.getBoundingClientRect?.();
  const vWidth = window?.innerWidth || document?.documentElement?.clientWidth;
  const vHeight =
    window?.innerHeight || document?.documentElement?.clientHeight;
  const efp = (x: number, y: number) => {
    return document?.elementFromPoint(x, y);
  };

  // Return false if it's not in the viewport
  if (
    rect?.right < 0 ||
    rect?.bottom < 0 ||
    rect?.left > vWidth ||
    rect?.top > vHeight
  )
    return false;

  // console.log("IS VISIBLE CALCS", {
  //   left: rect?.left,
  //   top: rect?.top,
  //   right: rect?.right,
  //   bottom: rect?.bottom,
  // }); // TODO remove this

  // Return true if any of its four corners are visible
  return (
    el.contains(efp(rect?.left, rect?.top)) ||
    el.contains(efp(rect?.right, rect?.top)) ||
    el.contains(efp(rect?.right, rect?.bottom)) ||
    el.contains(efp(rect?.left, rect?.bottom))
  );
};

/** Checks for duplicates in an array */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasDuplicates = (array: any[]): boolean => {
  return new Set(array).size !== array.length;
};

/** Get the duplicate values in an array */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDuplicates = (array: any[]): any[] => {
  const uniq = array
    .map((val) => {
      return {
        count: 1,
        val,
      };
    })
    .reduce((a, b) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      a[b.val] = (a[b.val] || 0) + b.count;
      return a;
    }, {});
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return Object.keys(uniq).filter((a) => uniq[a] > 1);
};

export const isValidEmail = (email: string): boolean => {
  /* eslint-disable no-useless-escape */
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /* eslint-enable no-useless-escape */
  return re.test(email);
};

/** Checks if on client side */
export const isClientSide = (): boolean => {
  return typeof window !== "undefined";
};

/**
 * Paste event handler to prevent pasting anything besides simple raw text
 */
export const sanitizedPasteEventHander: React.ClipboardEventHandler<
  HTMLDivElement
> = (e) => {
  // cancel paste
  e.preventDefault();
  // get text representation of clipboard
  const text = e?.clipboardData?.getData("text/plain");

  // sanitize text & insert it manually
  document.execCommand("insertHTML", false, sanitize(text));
  return false;
};

/**
 * Drag event handler to prevent dragging/dropping things
 */
export const sanitizedDragEventHandler: React.DragEventHandler<
  HTMLDivElement
> = (e) => {
  e.preventDefault();
  return false;
};

/**
 * Strips all HTML tags from string.
 */
export const sanitizeHtml = (value: string): string =>
  sanitize(value, { ALLOWED_TAGS: [] });

/**
 * Props to apply to every contenteditable we have in the app
 * Prevents dangerous behavior like pasting HTML, drag and drop random things, etc
 */
export const contentEditableSanitizerProps = {
  onPaste: sanitizedPasteEventHander,
  onDrop: sanitizedDragEventHandler,
  onDrag: sanitizedDragEventHandler,
};

// export const endStringWithThreeDots = (str: string): string =>
//   str.trimEnd().replace(/\.+$/, "") + "...";

/**
 * Function to create referral email links for multiple email clients.
 * @param emailId email id of the reffered user
 * @param subject subject of the email
 * @param body body of the email
 * @param cc cc'ed email id
 * @returns links for gmail, yahoo, outlook and default mailto link
 */
export const getReferralEmailMailToLinks = (
  emailId: string,
  subject: string,
  body?: string,
  cc?: string,
): {
  gmail: string;
  outlook: string;
  yahoo: string;
  main: string;
} => {
  const defaultValue = {
    gmail: "",
    outlook: "",
    yahoo: "",
    main: "",
  };
  try {
    if (!emailId) return defaultValue;
    const emailSubject = subject || "Abridge Clinician referral";
    const emailBody =
      body ||
      encodeURIComponent(
        "I'm excited to share a referral to Abridge Clinician — the fastest way to get through your clinical documentation.\n\nAbridge helps you securely record patient visits, and then automatically drafts SOAP notes using machine learning. I love the product, and think you will too!\n\nTo request access, just describe how you currently write notes here: https://abridgeapp.com/earlyaccess ",
      );
    const emailCC = cc;
    const gmail = `https://mail.google.com/mail/?hl=en&tf=cm&fs=1${
      emailId ? `&to=${encodeURIComponent(emailId)}` : ``
    }${emailSubject ? `&su=${encodeURIComponent(emailSubject)}` : ``}${
      emailBody ? `&body=${emailBody}` : ``
    }${emailCC ? `&cc=${encodeURIComponent(emailCC)}` : ``}`;

    const outlook = `https://outlook.com/?path=/mail/action/compose${
      emailId ? `&to=${encodeURIComponent(emailId)}` : ``
    }${emailSubject ? `&subject=${encodeURIComponent(emailSubject)}` : ``}${
      emailBody ? `&body=${emailBody}` : ``
    }${emailCC ? `&cc=${encodeURIComponent(emailCC)}` : ``}`;

    const yahoo = `https://compose.mail.yahoo.com/?${
      emailId ? `to=${encodeURIComponent(emailId)}` : ``
    }${emailSubject ? `&subject=${encodeURIComponent(emailSubject)}` : ``}${
      emailBody ? `&body=${emailBody}` : ``
    }${emailCC ? `&cc=${encodeURIComponent(emailCC)}` : ``}`;

    const main =
      "mailto:" +
      (emailId || "") +
      "?" +
      `${emailSubject ? `&subject=${encodeURIComponent(emailSubject)}` : ``}${
        emailBody ? `&body=${emailBody}` : ``
      }${emailCC ? `&cc=${encodeURIComponent(emailCC)}` : ``}`;

    return {
      gmail,
      outlook,
      yahoo,
      main: main,
    };
  } catch (err) {
    sentryError(err);
    return defaultValue;
  }
};

/*
  Utility function to disable react devtools in prod
*/
export const disableDevToolsInProduction = (deployEnv: DeployEnv): void => {
  try {
    if (deployEnv !== "production") return;
    const noop = () => undefined;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const DEV_TOOLS = window.__REACT_DEVTOOLS_GLOBAL_HOOK__;
    if (typeof DEV_TOOLS === "object") {
      for (const [key, value] of Object.entries(DEV_TOOLS)) {
        DEV_TOOLS[key] = typeof value === "function" ? noop : null;
      }
    }
  } catch (err) {
    sentryError(err);
  }
};

export const isWordMatchedFromSearch = (
  normalizedSearchWord: string,
  normalizedWordFromTranscript: string,
): boolean => {
  if (!size(normalizedSearchWord) || !size(normalizedWordFromTranscript)) {
    return false;
  }
  return normalizedWordFromTranscript.startsWith(normalizedSearchWord);
};

export const getStopWords = async (): Promise<string[]> => {
  try {
    const stopWordsModule = await import("public/json/stop-words.json");
    const stopWords: string[] = stopWordsModule?.default || [];
    if (!size(stopWords)) throw new Error("Stop words array is empty");
    // Make sure we normalize all the stop words
    return stopWords?.map((w) => normalizeWord(w));
  } catch (e) {
    sentryError(e);
    return [];
  }
};

export const sortNumerically = (arr: number[] = []): number[] => {
  return [...arr].sort((a, b) => a - b);
};

export const isDetectifyBot = (): boolean => {
  try {
    if (!isClientSide()) return false;
    const useragent = navigator?.userAgent;
    return useragent?.toLowerCase?.()?.includes("detectify");
  } catch (e) {
    return false;
  }
};

/**
 * Returns a formatted string with their professional for a user.
 */
export const getProviderNameWithTitle = (
  user?: Firestore_User_Account,
): string => {
  const truncateNameIfLong = (name: string, len = 21) =>
    size(name) > len ? `${name?.slice?.(0, len)}...` : name;
  if (user?.displayName) {
    return `${truncateNameIfLong(user?.displayName)}${
      user?.title && user?.title !== "other" ? `, ${user?.title}` : ``
    }`;
  }
  return "";
};

// Returns if scope is server-side
export const isServerSide = typeof window === "undefined";

export const randomNumberInRange = (
  min: number,
  max: number,
  whole = true,
): number => {
  const rnd = Math.random() * (max - min + 1) + min;
  return whole ? Math.floor(rnd) : parseFloat(rnd?.toFixed?.(2));
};

export const canUseDOM = (): boolean => {
  if (
    typeof window === "undefined" ||
    !window.document ||
    !window.document.createElement
  ) {
    return false;
  }
  return true;
};

export const pluralize = (count: number, noun: string, suffix = "s"): string =>
  `${noun}${count !== 1 ? suffix : ""}`;

export const clearAllTextSelections = (): void => {
  try {
    (window.getSelection
      ? window.getSelection()
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        document?.selection
    )?.empty?.();
  } catch (err) {
    sentryError(err);
  }
};

/**
 * Compares the length of text1 with the length of text2.
 * Returns true if text1's length is no more than $percentage different than text2
 * @param str1
 * @param str2
 * @param percentage
 */
export const isTextSimilar = (
  str1: string,
  str2: string,
  percentage = 25,
): boolean => {
  // Check for valid percentage
  if (percentage < 0 || percentage > 100) {
    throw new Error("Percentage must be between 0 and 100");
  }
  // check for blank strings. If BOTH strings are blank, that's 100% similarity
  if (!str1 && !str2) return true;

  // Calculate the absolute difference in lengths
  const lengthDifference = Math.abs(str1.length - str2.length);

  // Find the maximum length of the two strings
  const maxLength = Math.max(str1.length, str2.length);

  // Calculate the percentage difference
  const percentageDifference = (lengthDifference / maxLength) * 100;

  // Compare the percentage difference with the allowed percentage
  return percentageDifference <= percentage;
};

export const validateAccessCode = (str: string) => {
  try {
    return new RegExp(`^[0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ]{1,16}$`).test?.(
      str?.toUpperCase?.(),
    );
  } catch (err) {
    sentryError(err);
    return false;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAppDownloadQRCode = (org?: string): any => {
  switch (org) {
    default:
      return IOSDownloadQRCode;
  }
};

export const getBrowserDetails = (): {
  ua: string;
  browserVersion: string;
  browserName: string;
  osName: string;
  osVersion: string;
  tabId: string;
} => {
  return {
    ua: getUA,
    browserVersion,
    browserName,
    osName,
    osVersion,
    tabId: session?.getItem?.("abr-tab-id") || "not-set",
  };
};

export const isToday = (date = DateTime.now()): boolean => {
  return date?.toISODate() === DateTime.local().toISODate();
};
