export * from "./countryCodes";
export * from "./loginErrors";
export * from "./soap";
export * from "./orgSetings";

export const metaKeywords =
  "scribe medical, transcribe medical, notes for doctors, physician burnout, medication coding, speech to text apps, voice to text app, app for dictation, clinical documentation specialist, charting patients";

export enum LOCAL_STORAGE_KEYS {
  TEST_IND = "TEST_IND",
}

/**
 * Indicates if the application is running in a development environment (i.e. local machine)
 */
export const isDevelopment = process.env.NODE_ENV === "development";

// /**
//  * Fires function argument if `isDeveloptment` is true. Simple wrapper to avoid redundant if statements.
//  */
// export const ifIsDevelopment = (fn: () => void): void => {
//   fn?.();
// };

/**
 * Animation delay times in ms
 */
export enum ANIMATION_DELAYS {
  COPY_TO_CLIPBOARD = 500,
  ENCOUNTER_STATUS_CHANGE = 500,
  TOOLTIP_SHOW = 500,
  NOTE_SETTINGS_SAVE_CONFIRMATION = 1000,
  PERSIST_TOOLTIP_ADD_TIME_SPENT_TO_NOTE = 2000,
}
