import { useLDClient } from "launchdarkly-react-client-sdk";
import {
  getKnownFlagSchema,
  KnownFlagKey,
  KnownFlagValue,
} from "./known-flags";
import { useEffect, useState } from "react";

const warnNotInitializedDefaultValue = (client: string) => {
  // eslint-disable-next-line no-console
  console.warn(
    `Launch Darkly ${client} client not initialized, returning default value.`,
  );
};

const parseFlagValue = <K extends KnownFlagKey>(rawValue: unknown, flag: K) => {
  const schema = getKnownFlagSchema(flag);
  return schema.safeParse(rawValue);
};

/**
 * Hook for using a {@link KnownFlagKey known feature flag}.
 * Uses LaunchDarkly's variation method for efficient flag value retrieval.
 * @param flag - The feature flag key to watch
 * @param defaultValue - Default value to use if flag is not initialized
 * @returns Object containing the current flag value and initialization status
 * @example
 * const { value, initialized } = useKnownFeatureFlag('myFeature', false);
 */
export const useKnownFeatureFlag = <K extends KnownFlagKey>(
  flag: K,
  defaultValue: KnownFlagValue<K>,
): { value: KnownFlagValue<K>; initialized: boolean } => {
  const client = useLDClient();
  const [value, setValue] = useState<KnownFlagValue<K>>(defaultValue);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!client) {
      warnNotInitializedDefaultValue("sdk");
      return;
    }

    // Get initial value using variation method
    const rawFlagValue = client.variation(flag, defaultValue);
    const parseResult = parseFlagValue(rawFlagValue, flag);
    if (parseResult.success) {
      setValue(parseResult.data as KnownFlagValue<K>);
    } else {
      // eslint-disable-next-line no-console
      console.error(`Flag '${flag}' failed to parse`, parseResult.error);
      setValue(defaultValue);
    }

    // Set up flag listener for changes
    const flagListener = (newValue: unknown) => {
      const parseResult = parseFlagValue(newValue, flag);
      if (parseResult.success) {
        setValue(parseResult.data as KnownFlagValue<K>);
      } else {
        // eslint-disable-next-line no-console
        console.error(`Flag '${flag}' failed to parse`, parseResult.error);
      }
    };

    // Subscribe to flag value changes and update state when changes occur
    client.on(`change:${flag}`, flagListener);
    setInitialized(true);

    // Cleanup listener on unmount
    return () => {
      client.off(`change:${flag}`, flagListener);
    };
  }, [client, flag, defaultValue]);

  return { value, initialized };
};

export * from "./known-flags";
