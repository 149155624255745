import { useLiveQuery } from "dexie-react-hooks";
import { getDatabaseName, getDbInstance } from "./db";
import { useEnvironmentVariables } from "@contexts/environment";

const LOADING_SYMBOL = Symbol("loading");

export type UseLiveQueryWithStateResult<T> =
  | {
      loading: true;
    }
  | {
      data: T;
      loading: false;
    };

/**
 * Wrapper for {@link useLiveQuery} that adds explicit loading state.
 */
export const useLiveQueryWithState = <T>(
  querier: () => Promise<T> | T,
  deps: unknown[],
): UseLiveQueryWithStateResult<T> => {
  const data = useLiveQuery(querier, deps, LOADING_SYMBOL);

  if (data === LOADING_SYMBOL) {
    return {
      loading: true,
    };
  }

  return { data, loading: false };
};

/**
 * Returns instance of {@link AbridgeIndexedDB}.
 */
export const useDb = () => {
  const environmentVariables = useEnvironmentVariables();

  return getDbInstance(
    getDatabaseName(environmentVariables.NEXT_PUBLIC_DEPLOY_ENV),
  );
};
